<div class="psdk-user-reference">
  <div *ngIf="type === 'operator'">
    {{ label$ }}
    <app-operator
      [name$]="userName$"
      [label$]="label$"
      [id$]="userID$"
    ></app-operator>
  </div>
  <div [formGroup]="formGroup$" *ngIf="type === 'dropdown'">
    <mat-form-field class="psdk-full-width">
      <mat-select
        [value]="value$"
        [required]="bRequired$"
        [formControlName]="controlName$"
        [attr.data-test-id]="testId"
        (selectionChange)="fieldOnChange($event)"
      >
        <mat-option *ngFor="let opt of options$" [value]="opt.key">
          {{ opt.value }}
        </mat-option>
      </mat-select>
      <mat-label>{{ label$ }}</mat-label>
      <mat-error *ngIf="fieldControl.invalid">
        {{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>
  <div [formGroup]="formGroup$" *ngIf="type === 'searchbox'">
    <mat-form-field class="psdk-full-width">
      <mat-label>{{ label$ }}</mat-label>
      <input
        matInput
        [placeholder]=""
        [formControlName]="controlName$"
        [value]="value$"
        [required]="bRequired$"
        [matAutocomplete]="auto"
        [attr.data-test-id]="testId"
        (change)="fieldOnChange($event)"
        (blur)="fieldOnBlur($event)"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let opt of options$" [value]="opt.value">
          <span>{{ opt.value }}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="fieldControl.invalid">
        {{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
