<ng-container  *ngIf="bVisible$">
    <div class="simple-table-wrapper">
        <h3 *ngIf="label" className="label">{{label}} <span class="results-count">{{referenceList?.length}} results</span></h3>
        <table *ngIf="readOnlyMode" mat-table [dataSource]="rowData" class="mat-elevation-z8" id="readonly-table">
            <ng-container *ngFor="let dCol of processedFields" [matColumnDef]="dCol.config.name">
                <th mat-header-cell *matHeaderCellDef> {{ dCol.config.label }}</th>
                <td mat-cell *matCellDef="let element"> {{ element[dCol.config.name] }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <table *ngIf="editableMode" mat-table [dataSource]="elementsData" class="mat-elevation-z8" id="editable-table">
            <ng-container *ngFor="let dCol of fieldDefs; let i = index">
                <ng-container *ngIf="dCol.name != 'DeleteIcon'" [matColumnDef]="dCol.name">
                    <th mat-header-cell *matHeaderCellDef> {{ dCol.label }}</th>
                    <td mat-cell *matCellDef="let element">
                        <div [ngSwitch]="element[i].getPConnect().getComponentName()">
                            <app-text-input  *ngSwitchCase="'TextInput'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-text-input>
                            <app-text-area  *ngSwitchCase="'TextArea'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-text-area>
                            <app-text-content  *ngSwitchCase="'TextContent'" [pConn$]="element[i].getPConnect()"></app-text-content>
                            <app-check-box  *ngSwitchCase="'Checkbox'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-check-box>
                            <app-integer  *ngSwitchCase="'Integer'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-integer>
                            <app-date-time  *ngSwitchCase="'DateTime'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-date-time>
                            <app-date  *ngSwitchCase="'Date'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-date>
                            <app-time  *ngSwitchCase="'Time'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-time>
                            <app-percentage  *ngSwitchCase="'Percentage'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-percentage>
                            <app-email  *ngSwitchCase="'Email'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-email>
                            <app-url  *ngSwitchCase="'URL'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-url>
                            <app-currency  *ngSwitchCase="'Currency'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-currency>
                            <app-decimal  *ngSwitchCase="'Decimal'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-decimal>
                            <app-phone  *ngSwitchCase="'Phone'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-phone>
                            <app-radio-buttons  *ngSwitchCase="'RadioButtons'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-radio-buttons>
                            <app-dropdown  *ngSwitchCase="'Dropdown'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-dropdown>
                            <app-auto-complete  *ngSwitchCase="'AutoComplete'" [formGroup$]="formGroup$" [pConn$]="element[i].getPConnect()"></app-auto-complete>
                        </div> 
                    </td>
                </ng-container>
            </ng-container>
            <ng-container matColumnDef="DeleteIcon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; index as j">
                    <button id="delete-button" mat-icon-button (click)="deleteRecord(j)">
                        <img class="psdk-utility-card-action-svg-icon" src="{{menuIconOverride$}}">
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="psdk-no-records" id='no-records' *ngIf="referenceList?.length === 0">No Records Found.</div>
    </div>
    <button *ngIf="showAddRowButton" mat-button color="primary" style="font-size: 16px;" (click)="addRecord()">+ Add</button>
</ng-container>
