
  <div class="psdk-stages-bar">

    <div class="psdk-stages-chevron" *ngFor="let stage of arStageResults$">
      <div *ngIf="stage.visited_status =='completed'" class="psdk-stages-inner-past" ><img class="psdk-stages-icon" src="{{checkSvgIcon$}}">{{stage.name}}</div>
      <div *ngIf="stage.visited_status =='active'" class="psdk-stages-inner-present">{{stage.name}}</div>
      <div *ngIf="stage.visited_status =='future'" class="psdk-stages-inner-future" >{{stage.name}}</div>
    </div>

  </div>

  <div class="psdk-stages-divider"></div>
