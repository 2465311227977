<div class="psdk-grid-filter">
    <div>
        <app-material-details [arFields$]="arFields$"></app-material-details> 
    </div>
    <div>
        <app-material-details [arFields$]="arFields2$"></app-material-details> 
    </div>
    <div>
        <app-material-details [arFields$]="arFields3$"></app-material-details> 
    </div>
</div>