<div style="padding: 16px;">
    <div *ngFor="let field of arFields$">
        <div *ngIf="field.type === 'reference'; else showDetails">
            <app-view [pConn$]="field.pConn"></app-view>
        </div>
        <ng-template #showDetails>
            <div class="psdk-grid-filter">
                <div class="psdk-details-fields-label">
                    {{field.config.label}}
                </div>
                <div [ngSwitch]="field.type.toLowerCase()" class="psdk-csf-primary-field-value">
                        <span *ngSwitchCase="'textinput'" class="psdk-details-text-style">{{_getValue(field.config.value)}}</span>
                        <span *ngSwitchCase="'status'" class="psdk-details-status-style">{{_getValue(field.config.value)}}</span>
                        <a *ngSwitchCase="'phone'" as="a" href="tel: {{field.config.value}}">{{_getValue(field.config.value)}}</a>
                        <a *ngSwitchCase="'email'" href="mailto: {{field.config.value}}">{{_getValue(field.config.value)}}</a>
                        <span *ngSwitchCase="'date'" class="psdk-details-text-style">{{_formatDate(field.config.value, field.type)}}</span>
                        <span *ngSwitchCase="'caseoperator'"></span>
                        <span *ngSwitchDefault>{{_getValue(field.config.value)}}</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>

