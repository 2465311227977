
<!--
    top app now injected via the router
<app-top-app></app-top-app>
-->


<router-outlet></router-outlet>


