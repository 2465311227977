<form [formGroup]="formGroup$" name="flowContainer" class="psdk-flow-container">

    <div  *ngFor="let kid of arChildren$">
      <div [ngSwitch]="kid.getPConnect().getComponentName()">
        <app-view *ngSwitchCase="'View'" [formGroup$]="formGroup$" [pConn$]="kid.getPConnect()"></app-view>
        <app-case-create-stage *ngSwitchCase="'CaseCreateStage'" [formGroup$]="formGroup$" [pConn$]="kid.getPConnect()"></app-case-create-stage>
        <app-region *ngSwitchCase="'Region'" [formGroup$]="formGroup$" [pConn$]="kid.getPConnect()"></app-region>
        <div *ngSwitchDefault>Assignment Card wants component not yet available: {{kid.getPConnect().getComponentName()}}</div>
      </div>
      
    </div>

 </form> 

<div>
    <br>
    <div class="psdk-case-view-divider"></div>
    
    <app-action-buttons [arMainButtons$]="arMainButtons$" [arSecondaryButtons$]="arSecondaryButtons$"
    (ActionButtonClick)="onActionButtonClick($event)"></app-action-buttons>

</div>
