<div *ngIf="configProps$['visibility'] !== false" class="ng-view-top">
    <h3 *ngIf="title$ !== ''">{{title$}}</h3>
    <div [ngSwitch]="templateName$">
        <app-default-form *ngSwitchCase="'DefaultForm'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-default-form>
        <app-narrow-wide-form *ngSwitchCase="'NarrowWideForm'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-narrow-wide-form>
        <app-wide-narrow-form *ngSwitchCase="'WideNarrowForm'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-wide-narrow-form>
        <app-wide-narrow-page *ngSwitchCase="'WideNarrowPage'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-wide-narrow-page>
        <app-one-column *ngSwitchCase="'OneColumn'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-one-column>
        <app-one-column-page *ngSwitchCase="'OneColumnPage'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-one-column-page>
        <app-two-column *ngSwitchCase="'TwoColumn'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-two-column>
        <app-two-column-page *ngSwitchCase="'TwoColumnPage'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-two-column-page>
        <app-three-column *ngSwitchCase="'ThreeColumn'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-three-column>
        <app-three-column-page *ngSwitchCase="'ThreeColumnPage'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-three-column-page>
        <app-case-summary *ngSwitchCase="'CaseSummary'" [pConn$]="pConn$"></app-case-summary>
        <app-details *ngSwitchCase="'Details'" [pConn$]="pConn$"></app-details>
        <app-details-one-column *ngSwitchCase="'DetailsOneColumn'" [pConn$]="pConn$"></app-details-one-column>
        <app-details-two-column *ngSwitchCase="'DetailsTwoColumn'" [pConn$]="pConn$"></app-details-two-column>
        <app-details-three-column *ngSwitchCase="'DetailsThreeColumn'" [pConn$]="pConn$"></app-details-three-column>
        <app-list-view *ngSwitchCase="'ListView'" [pConn$]="pConn$" [bInForm$]="true"></app-list-view>
        <app-simple-table *ngSwitchCase="'SimpleTable'" [pConn$]="pConn$" [formGroup$]="formGroup$"></app-simple-table>
        <app-one-column-tab *ngSwitchCase="'OneColumnTab'" [pConn$]="pConn$" [formGroup$]="formGroup$"></app-one-column-tab>
        <app-case-view *ngSwitchCase="'CaseView'" [pConn$]="pConn$" [displayOnlyFA$]="displayOnlyFA$"></app-case-view>
        <app-list-page *ngSwitchCase="'ListPage'" [pConn$]="pConn$"></app-list-page>
        <app-data-reference *ngSwitchCase="'DataReference'" [formGroup$]="formGroup$" [pConn$]="pConn$"></app-data-reference>
        <div *ngSwitchDefault>
            <!-- show something to let us know we got an unknown templateName$ -->
            <h4 *ngIf="templateName$ !== ''">View template not recognized: [{{templateName$}}]</h4>
            <div *ngIf="displayOnlyFA$, else displayAll">
                <div *ngFor="let kid of arChildren$">
                    <div [ngSwitch]="kid.getPConnect().getComponentName()">
                        <app-flow-container *ngSwitchCase="'FlowContainer'" [pConn$]="kid.getPConnect()"></app-flow-container>
                        <app-region *ngSwitchCase="'Region'" [pConn$]="kid.getPConnect()"></app-region>
                        <app-view-container *ngSwitchCase="'ViewContainer'" [pConn$]="kid.getPConnect()"></app-view-container>
                        <div  *ngSwitchDefault >View Missing (displayOnlyFA): {{kid.getPConnect().getComponentName()}}</div>
                    </div>
                </div>
            </div>
            <ng-template #displayAll>
                <div *ngFor="let kid of arChildren$">
                    <div [ngSwitch]="kid.getPConnect().getComponentName()">
                        <app-page *ngSwitchCase="'Page'"  [pConn$]="kid.getPConnect()"></app-page>
                        <app-case-summary *ngSwitchCase="'CaseSummary'" [pConn$]="kid.getPConnect()"></app-case-summary>
                        <app-stages *ngSwitchCase="'Stages'" [pConn$]="kid.getPConnect()"></app-stages>
                        <app-flow-container *ngSwitchCase="'FlowContainer'" [pConn$]="kid.getPConnect()"></app-flow-container>
                        <app-feed-container *ngSwitchCase="'Pulse'" [pConn$]="kid.getPConnect()"></app-feed-container>
                        <app-region *ngSwitchCase="'Region'" [pConn$]="kid.getPConnect()"></app-region>
                        <app-app-shell *ngSwitchCase="'AppShell'" [pConn$]="kid.getPConnect()"></app-app-shell>
                        <app-view-container *ngSwitchCase="'ViewContainer'" [pConn$]="kid.getPConnect()"></app-view-container>
                        <!-- <app-view *ngSwitchCase="'View'" [pConn$]="kid.getPConnect()"></app-view> -->
                        <!-- <app-reference *ngSwitchCase="'reference'" [pConn$]="kid.getPConnect()"></app-reference> -->
                        <div  *ngSwitchDefault >View Missing (displayAll): {{kid.getPConnect().getComponentName()}}</div>
                    </div>
                </div>
            </ng-template>

        </div>

    </div>

</div>