<div class="psdk-label-infix-readonly">
    <span class="psdk-label-wrapper-readonly"> 
        <label class="psdk-label-readonly">{{label$}}</label>
      </span>
    <div class="psdk-modal-file-selector" *ngIf="bShowSelector$">
        <mat-spinner *ngIf="bLoading$" class="progress-spinner" diameter="40"></mat-spinner>
        <input hidden type="file" #uploader (change)="uploadMyFiles($event)"/>
    

        <button mat-stroked-button color="primary" [disabled]="bDisabled$" (click)="uploader.click()">
            Upload file
        </button>
    </div>
    
    
    <div class="psdk-attachment-list" *ngIf="arFileList$ != null && arFileList$.length > 0">
        <app-material-summary-list *ngIf="bShowJustDelete$; else showfull" [arItems$]="arFileList$" [menuIconOverride$]="'trash'" [menuIconOverrideAction$]="removeFileFromList$"></app-material-summary-list>
        <ng-template #showfull >
            <app-material-summary-list [arItems$]="arFileList$"  [menuIconOverrideAction$]="removeFileFromList$"></app-material-summary-list>
        </ng-template>
    </div>
    
</div>
