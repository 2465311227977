<div *ngIf="childrenToRender && childrenToRender.length > 0">
    <div *ngIf="!displayMultiRef">
        <div *ngFor="let kid of childrenToRender">
            <div [ngSwitch]="kid?.getPConnect().getComponentName()">
                <app-dropdown  *ngSwitchCase="'Dropdown'" [formGroup$]="formGroup$" [pConn$]="kid.getPConnect()"></app-dropdown>
                <app-auto-complete  *ngSwitchCase="'AutoComplete'" [formGroup$]="formGroup$" [pConn$]="kid.getPConnect()"></app-auto-complete>
                <app-simple-table-select *ngSwitchCase="'SimpleTableSelect'" [formGroup$]="formGroup$" [pConn$]="kid.getPConnect()"></app-simple-table-select>
            </div>
        </div>
    </div>
    <div *ngIf="displaySingleRef">
        <app-single-reference-readonly [pConn$]="firstChildPConnect()"></app-single-reference-readonly>
    </div>
    <div *ngIf="displayMultiRef">
        <app-multi-reference-readonly [pConn$]="firstChildPConnect()"></app-multi-reference-readonly>
    </div>
</div>