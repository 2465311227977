
<div>
    <mat-form-field class="psdk-search">
        
        <mat-label><mat-icon class="psdk-icon-search" style="transform: scale(1.2); width: 2rem; vertical-align: sub;" svgIcon="magnify" mastListIcon></mat-icon>  Search</mat-label>
        <input matInput (keyup)="applySearch($event)" placeholder="">
    </mat-form-field>

    <table mat-table [dataSource]="repeatList$" matSort>

        <ng-container *ngFor="let dCol of fields$" [matColumnDef]="dCol.name" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{dCol.label}}</th>
            <td mat-cell *matCellDef="let element"> {{element[dCol.name]}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns$"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns$;" (click)="rowClick(row)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 500]" showFirstLastButtons></mat-paginator>
</div>
