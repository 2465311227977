<div style="text-align: left;">
  <div class="psdk-utility">
    <div class="header">
      <div class="header-icon">
        <img  class="psdk-utility-svg-icon" src="{{headerSvgIcon$}}" >
      </div>
      <div class="header-text">{{headerText$}}</div>
      <div style="flex-grow: 1"></div>
      <div class="header-icon">
        <img  class="psdk-settings-svg-icon" src="{{settingsSvgIcon$}}" >
      </div>
    </div>
    <div class="psdk-case-view-divider"></div>
    <br>
    <div class="message">{{noItemsMessage$}}</div>
  </div>
</div>
