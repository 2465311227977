<div *ngIf="!bReadonly$ && bHasForm$ else noEdit">
    <div [formGroup]="formGroup$" *ngIf="bVisible$"  >
      <mat-form-field class="psdk-full-width" >
        <mat-label>{{label$}}</mat-label>
        <!-- <span matPrefix>% &nbsp;</span> -->
        <input matInput  [placeholder]="" type="number" step=".01" [value]="value$" [required]="bRequired$" 
        [formControlName]="controlName$" [attr.data-test-id]="testId"
        (change)="fieldOnChange($event)" (click)="fieldOnClick($event)"  (blur)="fieldOnBlur($event)" >
        <mat-error *ngIf="fieldControl.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <ng-template #noEdit>
    <app-text [pConn$]="pConn$" [formatAs$]="'text'"></app-text>
  </ng-template>