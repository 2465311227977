<div *ngIf="bShow$">


  <div *ngIf="bIsVertical$" class="psdk-vertical-stepper">
    <div *ngFor="let mainStep of arNavigationSteps$; let i = index" class="psdk-vertical-step">
      <div class="psdk-vertical-step-header">
        <div class="{{_getVIconClass(mainStep.visited_status)}}">
          <div class="psdk-vertical-step-icon-content">
            <span>{{i + 1}}</span>
          </div>
        </div>
        <div class="{{_getVLabelClass(mainStep.visited_status)}}">
          {{mainStep.name}}
        </div>
      </div>
      <div class="{{_getVBodyClass(i)}}">
        <ng-container *ngIf="mainStep?.steps">

          <ul style="padding-inline-start: 0rem; margin-left: -7px;">
            <li *ngFor="let subStep of mainStep.steps" class="psdk-sub-step-list">
              <div style="display: inline-flex">
                <img  *ngIf="subStep.visited_status == 'current'"  class="psdk-current-svg-icon" src="{{svgCurrent$}}" >
                <img  *ngIf="subStep.visited_status != 'current'" class="psdk-not-current-svg-icon" src="{{svgNotCurrent$}}" >
                <label *ngIf="subStep.visited_status == 'current'" class="psdk-sub-step-current">{{subStep.name}}</label>
                <label *ngIf="subStep.visited_status != 'current'" class="psdk-sub-step-not-current">{{subStep.name}}</label>
              </div>
              <div *ngIf="subStep.visited_status == 'current'">
                  <app-assignment-card [pConn$]="pConn$" [formGroup$]="formGroup$"
                      [arChildren$]="arChildren$"
                      [arMainButtons$]="arMainButtons$" [arSecondaryButtons$]="arSecondaryButtons$"
                      (ActionButtonClick)="onActionButtonClick($event)">
                  </app-assignment-card>
              </div>
  
            </li>
          </ul>
        </ng-container>
  
        <ng-container *ngIf="!mainStep?.steps && mainStep.visited_status == 'current'">
          <app-assignment-card [pConn$]="pConn$" [formGroup$]="formGroup$"
              [arChildren$]="arChildren$"
              [arMainButtons$]="arMainButtons$" [arSecondaryButtons$]="arSecondaryButtons$"
              (ActionButtonClick)="onActionButtonClick($event)">
          </app-assignment-card>
        </ng-container>
      </div>
    </div>
  </div>


<div *ngIf="!bIsVertical$" class="psdk-horizontal-stepper">
  <div class="psdk-horizontal-stepper-header-container">
    <ng-container *ngFor="let mainStep of arNavigationSteps$; let i = index" >
      <div class="psdk-horizontal-step-header">
        <div class="{{_getHIconClass(mainStep.visited_status)}}">
          <div class="psdk-horizontal-step-icon-content">
            <span>{{i + 1}}</span>
          </div>
        </div>
        <div class="{{_getHLabelClass(mainStep.visited_status)}}">
          <div class="psdk-horizontal-step-text-label">
            {{mainStep.name}}
          </div>
        </div>
      </div>
      <div *ngIf="_showHLine(i)" class="psdk-horizontal-step-line"></div>
    </ng-container>
  </div>
  <div *ngFor="let mainStep of arNavigationSteps$" class="psdk-horizontal-stepper-body">
    <ng-container *ngIf="mainStep?.steps">

      <ul style="padding-inline-start: 0rem; margin-left: 35px">
        <li *ngFor="let subStep of mainStep.steps" class="psdk-sub-step-list">
          <div style="display: inline-flex">
            <img  *ngIf="subStep.visited_status == 'current'"  class="psdk-current-svg-icon" src="{{svgCurrent$}}" >
            <img  *ngIf="subStep.visited_status != 'current'" class="psdk-not-current-svg-icon" src="{{svgNotCurrent$}}" >
            <label *ngIf="subStep.visited_status == 'current'" class="psdk-sub-step-current">{{subStep.name}}</label>
            <label *ngIf="subStep.visited_status != 'current'" class="psdk-sub-step-not-current">{{subStep.name}}</label>
          </div>
          <div *ngIf="subStep.visited_status == 'current'">
              <app-assignment-card [pConn$]="pConn$" [formGroup$]="formGroup$"
                  [arChildren$]="arChildren$"
                  [arMainButtons$]="arMainButtons$" [arSecondaryButtons$]="arSecondaryButtons$"
                  (ActionButtonClick)="onActionButtonClick($event)">
              </app-assignment-card>
          </div>

        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="!mainStep?.steps && mainStep.visited_status == 'current'">
      <app-assignment-card [pConn$]="pConn$" [formGroup$]="formGroup$"
          [arChildren$]="arChildren$"
          [arMainButtons$]="arMainButtons$" [arSecondaryButtons$]="arSecondaryButtons$"
          (ActionButtonClick)="onActionButtonClick($event)">
      </app-assignment-card>
    </ng-container>
  </div>
</div>


</div>

