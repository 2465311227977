
<div class="mc-resolution">
    <div class="mc-card">
        <div class="mc-header">
            Welcome!
        </div>
        <div class="mc-body">
            Thanks for selecting a package with us. <br><br>
            A technician will contact you with in the next couple of days to schedule an installation.<br><br>
            If you have any questions, you can contact us directly at <b>1-800-555-1234</b> or you can chat with us.
        </div>
    </div>
    <div>
        <img src="constellation/assets/cablechat.png" class="mc-chat-image">
        <button class="mc-chat-button" >Chat Now</button>
    </div>
</div>

